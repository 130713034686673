import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { Control, Icon, LatLng, tileLayer } from 'leaflet';

import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { Auth0UserDetailDto, GeoViewVehicleDto, VehicleDto, VehicleStatus } from '@services/api.service';
import 'leaflet-bing-layer';
import { VehicleCoords } from '@models/overview';
import { Store } from '@ngrx/store';
import { setSelectVehicle } from '@redux/modules/geoview/actions';
import { selectUserPreferences } from '@redux/index';
import { setUserPreferences } from '@redux/actions';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {

  // coord: any = [];
  options: any = {};
  layers;
  map;
  control: Control;
  centerLat = 50.528992;
  centerLon = 9.5598158;
  lastVehicleLat: any;
  lastVehicleLon: any;
  layersControl: any;
  public innerWidth: any;
  zoom: number;
  center: LatLng;
  defaultLayer = 'Google Satellite';

  @Input() vehicleToZoomOnAfterSelection: VehicleDto;
  @Input() vehiclesCoords: VehicleCoords[] = [];

  BING_KEY = 'AqtbPMt2B04zhcFw8kxg_w9zUk2L8rZWYok0b-xtdoOTPNKP9q-bH3sjm8AyjONX';


  LayersForMap = {
    // OpenStreet Map
    openstreetMapLayer: tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
    }),

    // Esri Maps
    esriImageryLayer: tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 19,
        attribution: '© <a  href="https://www.esri.com/en-us/geoview">Earthstar Geographics | Esri, FAO, NOAA, Powered by Esri</a>',
      },
    ),

    // Google Map Satellite
    googleMapsSatelliteLayer: tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      attribution: '© <a href="https://www.google.com/maps/">Imagery ©2022 Maxar Technologies, Map data ©2022</a>',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }),

    // Google Map Satellite Hybrid
    googleMapsHybridLayer: tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      attribution: '© <a href="https://www.google.com/maps/">Imagery ©2022 Maxar Technologies, Map data ©2022</a>',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }),

    // Mapbox Terrain
    mapboxTerrainLayer: tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZWxjaW9wYSIsImEiOiJjbDE0dXU2ejIwZTNsM2trYTAwbGJvMW05In0.7_vieuKTZBwX5Mt9sIcE7A', {
      attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      maxZoom: 20,
      tileSize: 512,
      zoomOffset: -1,
    }),

    bingAerialLayer: (L as any).tileLayer.bing({
      bingMapsKey: this.BING_KEY,
      attribution: '© <a href="https://www.bing.com/maps">Bing Maps</a> © <a href="https://www.mapbox.com/about/maps/">Earthstar Geographics SIO, © 2022 TomTom',
      imagerySet: 'AerialWithLabelsOnDemand',
      maxZoom: 24,
      detectRetina: true,
      zIndex: -4,
    }),
  };


  // Marker cluster stuff
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData: L.Marker[] = [];
  markerClusterOptions: L.MarkerClusterGroupOptions;

  vehicles: VehicleDto[];
  isLoading: boolean;
  user: Auth0UserDetailDto;
  private selectedLayer: string;
  userPreferences: any;

  constructor(private store: Store) {
  }

  ngOnInit() {
    this.getLayerControls();
    this.showMap();
    this.store.select(selectUserPreferences).subscribe((userPref) => {
      this.userPreferences = userPref;
      this.selectedLayer = userPref.selectedLayer || this.defaultLayer;
      console.log('userPref', userPref);
      if (this.userPreferences.hasChanged) {
        console.log('changed');
        this.showMap();
        console.log(this.map);
      }

    });
    // this is just for small screens, to center the icon of the tractor on the vieweable map (no on the center)
    this.innerWidth = window.innerWidth;
  }


  onMapReady(map) {
    this.map = map;
    this.map.on('baselayerchange', (event) => {
      this.store.dispatch(setUserPreferences({
        value: {
          ...this.userPreferences,
          selectedLayer: event.name,
        },
      }));
    });
    this.control = map.zoomControl;
    map.addControl(this.control.setPosition('topright'));
    Icon.Default.prototype.options.shadowUrl = 'assets/leaflet/marker-shadow.png';


    (L.Control as any).geocoder().addTo(this.map);

  }

  /*
  * Show map.
  */
  showMap() {

    this.options = {
      layers: [
        this.layersControl.baseLayers[this.selectedLayer || this.defaultLayer],
      ],
      zoom: 5,
      center: L.latLng(this.centerLat, this.centerLon),
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.lastVehicleLat === undefined && this.lastVehicleLon === undefined) {
      this.lastVehicleLat = this.centerLat;
      this.lastVehicleLon = this.centerLon;
    }
    if (this.innerWidth < 790) {
      this.map.panTo(new L.LatLng(this.centerLat, this.centerLon - 0.5));
    }
    if (this.innerWidth > 790 && this.innerWidth < 1050) {
      this.map.panTo(new L.LatLng(this.centerLat, this.centerLon - 1));
    } else {
      this.map.panTo(new L.LatLng(this.centerLat, this.centerLon));
    }
  }

  getLayerControls() {
    this.layersControl = {

      baseLayers: {
        'Google Satellite': this.LayersForMap.googleMapsSatelliteLayer,
        'Google Hybrid': this.LayersForMap.googleMapsHybridLayer,
        'Mapbox Terrain': this.LayersForMap.mapboxTerrainLayer,
        'Esri Imagery': this.LayersForMap.esriImageryLayer,
        'Bing Aerial': this.LayersForMap.bingAerialLayer,
        'OpenStreet Map': this.LayersForMap.openstreetMapLayer,
      },
    };
    return this.layersControl;
  }

  /*
   --------------------------------------------------------------------------
      MAKER CLUSTER
   --------------------------------------------------------------------------
  */

  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  refreshData(): void {
    this.markerClusterData = this.generateMarkers(this.vehiclesCoords);
  }

  generateMarkers(vehicleCoords: VehicleCoords[]): L.Marker[] {
    const markers: L.Marker[] = [];
    const greenTractorIcon = L.icon({
      iconUrl: 'assets/leaflet/location_tractor_greenLarge_whiteBorder.svg',
      shadowUrl: 'assets/leaflet/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [50, 50],
      iconAnchor: [25, 50],
      shadowAnchor: [25, 50],
      popupAnchor: [-12, -33],
    });

    const greenWithAlarmTractorIcon = L.icon({
      iconUrl: 'assets/leaflet/location_tractor_greenLarge_alarm_whiteBorder.svg',
      shadowUrl: 'assets/leaflet/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [50, 50],
      iconAnchor: [25, 50],
      shadowAnchor: [25, 50],
      popupAnchor: [-12, -33],
    });

    const greenWithDueMaintenanceTractorIcon = L.icon({
      iconUrl: 'assets/leaflet/location_tractor_greenLarge_maintenance_whiteBorder.svg',
      shadowUrl: 'assets/leaflet/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [50, 50],
      iconAnchor: [25, 50],
      shadowAnchor: [25, 50],
      popupAnchor: [-12, -33],
    });

    const greyWithDueMaintenanceTractorIcon = L.icon({
      iconUrl: 'assets/leaflet/location_tractor_greyLarge_maintenance_whiteBorder.svg',
      shadowUrl: 'assets/leaflet/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [50, 50],
      iconAnchor: [25, 50],
      shadowAnchor: [25, 50],
      popupAnchor: [-12, -33],
    });

    const greyTractorIcon = L.icon({
      iconUrl: 'assets/leaflet/location_tractor_greyLarge_whiteBorder.svg',
      shadowUrl: 'assets/leaflet/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [50, 50],
      iconAnchor: [25, 50],
      shadowAnchor: [25, 50],
      popupAnchor: [-12, -33],
    });

    const greyWithAlarmTractorIcon = L.icon({
      iconUrl: 'assets/leaflet/location_tractor_greyLarge_alarm_whiteBorder.svg',
      shadowUrl: 'assets/leaflet/marker-shadow.png',
      iconSize: [50, 50],
      shadowSize: [50, 50],
      iconAnchor: [25, 50],
      shadowAnchor: [25, 50],
      popupAnchor: [-12, -33],
    });
    const iconsMap = {
      [VehicleStatus.Running]: greenTractorIcon,
      [VehicleStatus.ActiveAlarmsRunning]: greenWithAlarmTractorIcon,
      [VehicleStatus.DueMaintenanceActiveAlarmsRunning]: greenWithAlarmTractorIcon,
      [VehicleStatus.DueMaintenanceRunning]: greenWithDueMaintenanceTractorIcon,
      [VehicleStatus.DueMaintenanceNotRunning]: greyWithDueMaintenanceTractorIcon,
      [VehicleStatus.ActiveAlarmsNotRunning]: greyWithAlarmTractorIcon,
      [VehicleStatus.NotRunning]: greyTractorIcon,
      [VehicleStatus.DueMaintenanceActiveAlarmsNotRunning]: greyWithAlarmTractorIcon,

    };
    for (const vehicleCoord of vehicleCoords) {
      markers.push(L.marker([vehicleCoord.latLng.lat, vehicleCoord.latLng.lng], {
        icon: iconsMap['vehicleStatus' in vehicleCoord.vehicle ? vehicleCoord.vehicle.vehicleStatus : VehicleStatus.NotRunning]
          || greyTractorIcon,
      })
        .bindPopup('<p>' + vehicleCoord.vehicle.vin + '</p>')
        .on('click', (clickEvent) => {


          this.store.dispatch(setSelectVehicle({ value: (vehicleCoord.vehicle as GeoViewVehicleDto) }));
        }));
    }
    return markers;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.vehicleToZoomOnAfterSelection !== undefined) {
      this.zoomOnVehicle(this.vehicleToZoomOnAfterSelection);
    }
    if ((this.vehiclesCoords !== undefined) &&
      (changes.vehiclesCoords !== undefined) && (changes.vehiclesCoords.isFirstChange() ||
        (changes.vehiclesCoords.currentValue !== changes.vehiclesCoords.previousValue))) {
      this.refreshData();
    }
  }

  zoomOnVehicle(vehicle: GeoViewVehicleDto) {
    if (vehicle.latitude === undefined || vehicle.longitude === undefined) {
      vehicle.latitude = this.centerLat;
      vehicle.longitude = this.centerLon;
    }
    this.lastVehicleLat = vehicle.latitude;
    this.lastVehicleLon = vehicle.longitude;
    const vehicleLatLng = new L.LatLng(vehicle.latitude, vehicle.longitude);
    // this.map.panTo(vehicleLatLng);
    this.center = vehicleLatLng;
    this.zoom = 16;
  }
}
