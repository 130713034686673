import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirection',
  template: `
    <div></div>`,
  styles: [`div {
  }`],
})
export class RedirectionComponent implements OnInit {
  constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    this.auth.user$.subscribe
    (user => {
      const data = this.route.snapshot.data;
      if (user['sdf-user-roles'].some(role => data.roles?.includes(role))) {
        this.router.navigate([data.redirectTo], { replaceUrl: true });
      } else {
        this.router.navigate([data.alternativeRoute], { replaceUrl: true });
      }
    });
  }
}
