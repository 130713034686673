import { GeoViewVehicleDto, SbdDto } from '../_services/api.service';
import * as L from 'leaflet';

export class VehicleCoords {
  vehicle?: GeoViewVehicleDto;

  constructor(vehicle: GeoViewVehicleDto, latLng: L.LatLng) {
    this.vehicle = vehicle;
    this.latLng = latLng;
  }
  latLng?: L.LatLng;

}

export class VehicleSbdCoords {
  vehicle?: SbdDto;

  constructor(vehicle: SbdDto, latLng: L.LatLng) {
    this.vehicle = vehicle;
    this.latLng = latLng;
  }
  latLng?: L.LatLng;

}



